import moment from 'moment'

import store from '@/store'

import { vm } from '@/main'

import { $classes } from '@/main'

import { isOnline } from '@/functions/network'

import {
	getSpeedOfConnection,
	getMinimumSpeedOfConnection,
} from '@/functions/network'

import { limitObjects } from '@/functions/filters'

let _creatingEventInProgress = null

import { getAsBool } from '@/functions/env'

const VUE_APP_MODULE_SEND_PROCESSES_ENABLED = getAsBool(
	'VUE_APP_MODULE_SEND_PROCESSES_ENABLED'
)

const created = () => moment().format('YYYY-MM-DD HH:mm:ss'),
	getUserId = () => store.state.CurrentUser?.instance?.id,
	getInjection = function (code, payload = []) {
		const injections = [
			...payload,
			{
				code: 'user_id',
				ret: getUserId(),
			},
			{
				code: 'user_login',
				ret: store.state.CurrentUser.instance?.login,
			},
			{
				code: 'network_speed',
				ret: () => getSpeedOfConnection(),
			},
			{
				code: 'network_speed_minimum',
				ret: () => getMinimumSpeedOfConnection(),
			},
			{
				code: 'last_change_datetime',
				ret: store.getters['CurrentDatabase/getLastChangeOnRemote'],
			},
			// {
			// 	code: 'last_sync_duration',
			// 	ret:  store.getters['CurrentDatabase/getLastSyncDuration'],
			// },
		]

		let i = injections.findIndex((e) => (e.code == code ? true : false))

		if (i >= 0) {
			return injections[i]?.ret
		} else {
			console.debug(`injections`, injections, payload)
			throw new Error(
				`[getInjection] no predefinited injection for '${code}'`
			)
		}
	},
	permittedStatuses = ['Success', 'Fail', 'Warning', 'Info'],
	notificationConfig = {
		timer: 3,
		showLeftIcn: false,
	},
	// eslint-disable-next-line no-unused-vars
	skipNotificationForEvents = [
		'version-fetch-fail',
		'version-check-fail',
		'db-sync-fail-for-collection',
		'db-validate-success',
	],
	predefinedEvents = [
		{
			code: 'db-cache-remove',
			status: 'Success',
			details: 'Usuwanie danych pacjentów.',
		},
		{
			code: 'db-sync-success',
			status: 'Success',
			details: 'Synchronizacja danych zakończona.',
			conditions: {
				time: {
					type: 'one per peroid',
					valueOfPeriod: 10,
					unitOfPeriod: 'seconds',
				},
			},
		},
		{
			code: 'db-sync-started',
			status: 'Info',
			details: 'Synchronizacja danych rozpoczęta.',
		},
		{
			code: 'db-sync-aborted',
			status: 'Info',
			details: 'Synchronizacja danych przerwana.',
		},
		{
			code: 'db-sync-start-fail',
			status: 'Fail',
			details: 'Synchronizacja danych nie rozpoczęta.',
		},
		{
			code: 'db-sync-start-fail-low-app-version',
			status: 'Fail',
			details:
				'Synchronizacja danych nie rozpoczęta, wymagane uaktualnienie aplikacji.',
		},
		{
			code: 'db-sync-started-default-full',
			status: 'Info',
			details: 'Synchronizacja danych rozpoczęta.',
		},
		{
			code: 'db-sync-started-default-partial',
			status: 'Info',
			details: 'Częściowa synchronizacja danych rozpoczęta.',
		},
		{
			code: 'db-sync-started-zwr-partial',
			status: 'Info',
			details: 'Częściowa synchronizacja zwr danych rozpoczęta.',
		},
		{
			code: 'db-sync-started-zwr-partial-1',
			status: 'Info',
			details: 'Częściowa synchronizacja zwr danych rozpoczęta. (1 z 2)',
		},
		{
			code: 'db-sync-started-zwr-partial-2',
			status: 'Info',
			details: 'Częściowa synchronizacja zwr danych rozpoczęta. (2 z 2)',
		},
		{
			code: 'db-sync-started-partial-stay-room',
			status: 'Info',
			details:
				'Częściowa synchronizacja po uaktualnieniu pokoju rozpoczęta.',
		},
		{
			code: 'db-sync-change-remote-detected',
			status: 'Info',
			details:
				'Wykryto potrzebę synchronizacji, ostatnia zmiana: {{last_change_datetime}}',
			injection: true,
		},
		{
			code: 'db-sync-change-remote-detected-without-date',
			status: 'Info',
			details:
				'Wykryto potrzebę synchronizacji.',
		},
		
		{
			code: 'db-sync-started-after-validation',
			status: 'Info',
			details:
				'Częściowa synchronizacja po wykryciu brakujących dokumentów rozpoczęta.',
		},
		{
			code: 'db-sync-started-after-note',
			status: 'Info',
			details:
				'Częściowa synchronizacja po zmianach w notatkach rozpoczęta.',
		},
		{
			code: 'db-sync-started-after-sign',
			status: 'Info',
			details:
				'Częściowa synchronizacja po podpisie dokumentu rozpoczęta.',
		},
		{
			code: 'db-sync-started-after-sign',
			status: 'Info',
			details:
				'Częściowa synchronizacja po dodaniu zlecenia rozpoczęta.',
		},
		{
			code: 'db-sync-started-after-patient-profile',
			status: 'Info',
			details:
				'Częściowa synchronizacja po zmianach w zdjęciach profilowych pacjentów rozpoczęta.',
		},
		{
			code: 'db-sync-started-tray-change',
			status: 'Info',
			details:
				'Częściowa synchronizacja zmian w tacy leków rozpoczęta.',
		},
		{
			code: 'db-sync-fail',
			status: 'Fail',
			details: 'Synchronizacja danych zakończona błędem.',
		},
		{
			code: 'db-sync-fail-by-offline',
			status: 'Fail',
			details: 'Synchronizacja danych zakończona błędem z powodu braku połączenia z internetem.',
		},
		{
			code: 'db-error-db6',
			status: 'Fail',
			details: 'Wystąpił błąd DB6, w wyniku czego dane zostały usunięte.',
		},
		
		{
			code: 'db-error-db8',
			status: 'Fail',
			details: 'Wystąpił błąd DB8, w wyniku czego dane zostały usunięte.',
		},
		{
			code: 'db-error-en0',
			status: 'Fail',
			details: 'Wystąpił błąd EN0, w wyniku czego dane zostały usunięte.',
		},
		{
			code: 'user-login-online-success',
			status: 'Success',
			details: 'Logowanie online uzytkownika {{user_login}}.',
			injection: true,
		},
		{
			code: 'user-login-offline-success',
			status: 'Success',
			details: 'Logowanie offline uzytkownika {{user_login}}.',
			injection: true,
		},
		{
			code: 'user-login-offline-fail',
			status: 'Fail',
			details: 'Logowanie offline.',
		},
		{
			code: 'user-logout-success',
			status: 'Success',
			details: 'Wylogowanie uzytkownika {{user_login}}.',
			injection: true,
		},
		{
			code: 'user-data-update',
			status: 'Success',
			details: 'Uaktualnienie danych zalogowanego uzytkownika.',
		},
		{
			code: 'network-internet-connection-fail',
			status: 'Fail',
			details: 'Brak dostępu do internetu.',
			notificationConfig: {
				...notificationConfig,
				showCloseIcn: true,
				time: 60,
			},
			conditions: {
				time: {
					type: 'one per peroid',
					valueOfPeriod: 1,
					unitOfPeriod: 'minutes',
				},
			},
		},
		{
			code: 'network-internet-connection-back-success',
			status: 'Success',
			details: 'Powrócił dostępu do internetu.',
			notificationConfig: {
				...notificationConfig,
				showCloseIcn: false,
				time: 60,
			},
			conditions: {
				time: {
					type: 'one per peroid',
					valueOfPeriod: 10,
					unitOfPeriod: 'seconds',
				},
			},
		},
		{
			code: 'network-server-connection-fail',
			status: 'Fail',
			details: 'Brak połączenia z serwerem.',
			notificationConfig: {
				...notificationConfig,
				showCloseIcn: true,
				time: 60,
			},
			conditions: {
				time: {
					type: 'one per peroid',
					valueOfPeriod: 1,
					unitOfPeriod: 'minutes',
				},
				notExist: {
					codes: ['network-internet-connection-fail'],
				},
			},
		},
		{
			code: 'network-server-connection-back-success',
			status: 'Success',
			details: 'Powróciło połączenie z serwerem.',
			notificationConfig: {
				...notificationConfig,
				showCloseIcn: false,
				time: 60,
			},
			conditions: {
				time: {
					type: 'one per peroid',
					valueOfPeriod: 10,
					unitOfPeriod: 'seconds',
				},
			},
		},
		{
			code: 'network-internet-speed-fail',
			status: 'Fail',
			details:
				'Prędkość połączenia jest zbyt wolna, wynosi {{network_speed}} Mb/s, minimalna prędkość do poprawnego działania to {{network_speed_minimum}} Mb/s.',
			injection: true,
			conditions: {
				time: {
					type: 'one per peroid',
					valueOfPeriod: 1,
					unitOfPeriod: 'minutes',
				},
				notExist: {
					codes: ['network-server-connection-fail'],
				},
			},
			notificationConfig: {
				...notificationConfig,
				showCloseIcn: true,
				time: 60,
			},
		},
		{
			code: 'network-internet-speed-warning',
			status: 'Warning',
			details:
				'Prędkość połączenia jest wolna, wynosi {{network_speed}} Mb/s, minimalna prędkość do poprawnego działania to {{network_speed_minimum}} Mb/s.',
			injection: true,
			conditions: {
				time: {
					type: 'one per peroid',
					valueOfPeriod: 1,
					unitOfPeriod: 'minutes',
				},
			},
			notificationConfig: {
				...notificationConfig,
				showCloseIcn: true,
				time: 60,
			},
		},
		{
			code: 'auth-access-token-expired', // deprecated by PD-2376
			status: 'Warning',
			details:
				'Dane uwierzytelniające straciły swoją wazność. (access_token)',
		},
		{
			code: 'auth-refresh-token-expired',
			status: 'Warning',
			details:
				'Wylogowano z powodu braku aktywności',
		},
		{
			code: 'auth-refresh-token-renew-fail',
			status: 'Fail',
			details:
				'Podczas odnawiania danych uwierzytelniających wystapił błąd.',
		},
		{
			code: 'auth-refresh-token-renew-success',
			status: 'Success',
			details: 'Odnowiono dane autoryzacyjne z powodzeniem.',
		},
		{
			code: 'fetch-classes-public-fail',
			status: 'Success',
			details: 'Podczas pobierania klas (publicznych) wystąpił błąd.',
		},
		{
			code: 'report-problem-failed',
			status: 'Fail',
			details: 'Nie udało się utworzyć zgłoszenia problemu.',
		},
		{
			code: 'report-problem-failed-send-later',
			status: 'Fail',
			details:
				'Nie udało się utworzyć zgłoszenia problemu, zostało ono zakolejkowane.',
		},
		{
			code: 'object-is-signed',
			status: 'Warning',
			details:
				'Dokument został wcześniej podpisany, nie można go podpisać ponownie.',
		},
		{
			code: 'report-problem-sending-pending',
			status: 'Info',
			details:
				'Wysyłanie raportów o błędach z kolejki.',
		},
		{
			code: 'version-fetch-fail',
			status: 'Fail',
			details: 'Błąd przy pobieraniu nowej wersji aplikacji',
		},
		{
			code: 'version-check-fail',
			status: 'Fail',
			details: 'Błąd przy sprawdzaniu aktualnej wersji aplikacji',
		},
		{
			code: 'db-sync-fail-for-collection',
			status: 'Fail',
			details: `Synchronizacja kolekcji {{collection_name}}, wystąpił błąd: {{error_message}}.`,
		},
		{
			code: 'db-validate-success',
			status: 'Success',
			details: `Walidacja danych aplikacji.`,
		},
		{
			code: 'db-sync-planned',
			status: 'Info',
			details: 'Zaplanowano nastepną synchronizacje za {{time_humanized}}.',
		},
	],
	getVariables = function (str) {
		const regex = /{{\s*([^{]*{([^{]*):\s*(.*?)}.*?|[^{]*)\s*}}/g,
			variables = []

		let m

		while ((m = regex.exec(str)) !== null) {
			if (m.index === regex.lastIndex) {
				regex.lastIndex++
			}

			m.forEach((match, groupIndex) => {
				if (match !== 'undefined' && groupIndex === 1) {
					variables[variables.length] = match
				}
			})
		}

		return variables
	},
	canCreateEvent = function () {
		if (_creatingEventInProgress) return false
		return true
	},
	getConfigForCode = function (code) {
		const i = predefinedEvents.findIndex((ev) => ev.code === code)

		if (i > -1) return predefinedEvents[i]
	},
	getCodesForConditionNotExistByCode = function (code) {
		let c = code,
			codes = []

		while (c) {
			codes.push(c)

			const config = getConfigForCode(c)

			c = config?.conditions?.notExist?.code
		}

		return codes
	},
	getCodesForConditionNotExistByCodes = function (codes = []) {
		let codes2 = []

		codes.forEach(
			(code) =>
				(codes2 = [
					...new Set([
						...codes2,
						...getCodesForConditionNotExistByCode(code),
					]),
				])
		)

		return codes2
	},
	// removeNotification = function (notification) {
	// 	vm.$notification.remove(notification)
	// },
	// removeAllNotifications = function () {
	// 	vm.$notification.removeAll()
	// },
	getEventsForCode = function (code) {
		const user_id = getUserId()

		const events = store.state.CurrentLog.events?.filter(
			(event) => event.code === code && event.user_id === user_id
		)

		return events
	},
	getEventsForCodes = function (codes = []) {
		const user_id = getUserId()

		const events = store.state.CurrentLog.events?.filter(
			(event) => codes.includes(event.code) && event.user_id === user_id
		)

		return events
	},
	standardizeEvent = function (event) {
		const detailsLength = 250

		const { details } = event

		if (details?.length > detailsLength)
			event.details = `${details?.substring(0, detailsLength)}...`

		return event
	},
	getEventFromCode = function (code, payload = {}) {
		let i = predefinedEvents.findIndex((e) =>
			e.code == code ? true : false
		)

		if (i >= 0) {
			const ev = predefinedEvents[i]

			if (ev?.injection || payload?.injections) {
				let details = ev?.details || ''

				getVariables(ev.details)
					.map((e) => ({
						rep: `{{${e}}}`,
						value: getInjection(e, payload?.injections || []),
					}))
					.forEach((e) => (details = details.replace(e.rep, e.value)))

				return { ...ev, details }
			} else return ev
		} else throw new Error(`[getEventFromCode] no predefinited event`)
	},
	validateEvent = function (event) {
		if (!permittedStatuses.includes(event.status))
			throw new Error(`[validateEvent] no permitted type of status`)

		return event
	},
	shouldAddEvent = function (code) {
		const ev = getEventFromCode(code)
		let isError = false

		if (ev?.conditions) {
			const { time, notExist } = ev?.conditions

			if (time) {
				const { type } = time

				if (type === 'one per peroid') {
					const { unitOfPeriod, valueOfPeriod: valueOfPeriod } = time

					let events = []

					if (notExist) {
						const code2 = notExist.code,
							codes = getCodesForConditionNotExistByCodes([
								code,
								code2,
							])

						events = getEventsForCodes(codes)

						console.debug(`shouldAddEvent`, codes, events)
					} else {
						events = getEventsForCode(ev.code)
					}

					const eventsFiltered = limitObjects(
						events,
						'datetimeCreated',
						valueOfPeriod,
						unitOfPeriod,
						true
					)

					if (eventsFiltered?.length > 0) isError = true
				}
			}
		} if (skipNotificationForEvents.includes(code)) isError = true

		if (isError) {
			throw new Error(
				`[shouldAddEvent] the requirements have not been met`
			)
		}

		return ev
	},
	createNotification = async function (event, retryTimes = 3) {
		try {
			// add notification
			if (event?.notificationConfig) {
				const type = getTypeOfNotification(event?.status)

				if (type) {
					if (vm?.$notification)
						return vm.$notification?.[type](
							event.details,
							event.notificationConfig
						)
					else if (retryTimes >= 0) {
						const sec = Math.ceil(1000 * Math.abs(4 - retryTimes))
						console.debug(
							`[createNotification] no vm?.$notification object, retrying in ${sec} ms.`
						)
						await setTimeout(
							async () =>
								await createNotification(event, --retryTimes),
							sec
						)
					}
				}
			}
		} catch (error) {
			console.warn(`Unable to add notification`, error)
		}
	},
	getTypeOfNotification = function (status) {
		/* 
			success
			warning
			error
		*/
		const c = {
			Success: 'success',
			Fail: 'error',
			Warning: 'warning',
			Info: 'new',
		}

		const r = c?.[status]
		if (r) return r
		else throw new Error(`[getTypeOfNotification] no status in config`)
	}

export const createEvent = async function (event, payload = null) {
		if (!canCreateEvent())
			return await setTimeout(
				async () => await createEvent(event, payload),
				100
			)

		_creatingEventInProgress = true

		let ev = validateEvent({
			...event,
			datetimeCreated: created(),
			notificationConfig: {
				...notificationConfig,
				...event.notificationConfig,
			},
			stack: new Error().stack,
		})

		ev = standardizeEvent(ev)

		const user_id = getUserId()
		
		let notification = null
		
		if(!ev?.skipNotification)
		    notification = await createNotification(ev)

		ev = {
			...ev,
			user_id,
			notification,
			payload: payload && JSON.stringify(payload),
		}

		const id = await sendProcess(ev)

		await store.dispatch('CurrentLog/appendEvent', {
			...ev,
			id: id || null,
			payload,
		})

		_creatingEventInProgress = false
	},
	createEventFromCode = async function (code, payload = {}) {
		try {
			shouldAddEvent(code)
			await createEvent(
				getEventFromCode(code, payload),
				payload?.payload || null
			)
		} catch (error) {
			console.warn(error)
		}
	},
	mapArrayToEvents = async function (arr = [], status = 'Info', options = {}) {
		await Promise.all(
			arr.map(
				async (it) =>
					await createEvent({
						...options,
						status: status,
						details: it,
					})
			)
		)
	},
	sendProcess = async function (event) {
		try {
			if (VUE_APP_MODULE_SEND_PROCESSES_ENABLED) {
				await isOnline()

				await $classes.loadPublic()

				if ($classes?.AppProcess && !$classes.AppProcess?.add_process) {
					throw new Error(
						`[sendProcess] no $classes.AppProcess.add_process`
					)
				}

				const { id } = await $classes.AppProcess.add_process({
					...event,
					datetime_created: event.datetimeCreated,
					person_id: event.user_id,
					status: event.status?.toLowerCase(),
					title: event.details,
					details: event.payload,
					code: event.code,
				})

				return id
			}
		} catch (e) {
			console.warn(e)
		}
	},
	sendPendingProcess = async function (event) {
		console.debug(`[sendPendingProcess] event:`, event)
		try {
			await isOnline()

			const id = await sendProcess(event)

			if (id)
				await store.dispatch('CurrentLog/markPendingProcessAsSend', {
					...event,
					id,
				})
		} catch (e) {
			console.warn(e)
		}
	}

export default createEvent
